export default class History {
	constructor(app) {
		this.app = app;
		this.index = -1;
		this.state = [];
		this.$ready = false;
	}

	connect(master, local) {
		this._master = master;
		this._local = local;

		this.$ready = true;
		this.track();
	}

	/**
	 * Starts tracking changes
	 */
	track() {
		if (!this.$ready) return;
		this.index = -1;
		this.state = [];
		this.push([["init", this.app.getValues()]]);
	}

	/**
	 * Reset state and load initial data
	 */
	reset() {
		if (this.state.length < 2) return;
		this.$ready = false;

		this.index = 0;
		const state = this.state[this.index];

		this.state = [state];
		this.app.setValues({
			item: webix.copy(state[0].item),
			linkItem: webix.copy(state[0].linkItem),
			data: this.copy(state[0].data),
			links: this.copy(state[0].links),
			shapes: state[0].shapes, // restore shapes
		});

		this.$ready = true;
		this.app.callEvent("UndoChange");
	}

	/**
	 * Сopy dataset
	 * @param arr {Array} dataset
	 * @return {Array} new dataset
	 */
	copy(arr) {
		return arr.map(a => webix.copy(a));
	}

	/**
	 * Parse data to the store and update the order
	 * @param store {DataStore}
	 * @param data {Array} dataset
	 */
	parse(store, data) {
		for (let i = 0; i < data.length; i++) {
			const obj = data[i];
			if (!store.pull[obj.id]) store.order.push(obj.id);
			store.pull[obj.id] = obj;
		}
		store.refresh();
	}

	/**
	 * Undo changes
	 */
	undo() {
		if (!this.hasUndo()) return;
		this.$inProgress = true;
		const states = this.state[this.index];
		this.index--;
		states.forEach(state => {
			if (state.data && state.data.length)
				this.parse(this._local.data(), this.copy(state.data));

			if (state.links && state.links.length)
				this.parse(this._local.links().data, this.copy(state.links));

			if (state.mode == "common") {
				this._master.UpdateCommonValue(state.prev, state.target);
			} else if (state.mode != "autoplace") {
				const store = state.link
					? this._local.links().data
					: this._local.data();
				if (state.mode == "update") this.parse(store, [webix.copy(state.prev)]);
				else if (state.mode == "add") store.remove(state.id);
				else if (state.mode == "remove") store.add(webix.copy(state.prev));
			}
		});

		this.$inProgress = false;
		this.app.callEvent("UndoChange");
	}

	/**
	 * Redo changes
	 */
	redo() {
		if (!this.hasRedo()) return;
		this.$inProgress = true;

		this.index++;
		const states = this.state[this.index];
		states.forEach(state => {
			if (state.mode == "common") {
				this._master.UpdateCommonValue(state.value, state.target);
			} else if (state.mode == "autoplace") {
				this._master.Autoplace();
			} else {
				const m = state.link ? "Link" : "Block";
				if (state.mode == "update")
					this._local["update" + m](state.id, webix.copy(state.value));
				else if (state.mode == "add")
					this._local["add" + m](webix.copy(state.value));
				else if (state.mode == "remove") this._local["remove" + m](state.id);
			}
		});

		this.$inProgress = false;
		this.app.callEvent("UndoChange");
	}

	/**
	 * Adds changes to history
	 * @param data {Array} - an array of one or more arrays of history params:
	 *	0 - mode {string} determines the type of change
	 *	1 - obj {Object} history state object
	 *	2 - id {number|string} data id
	 *  4 - link {Boolean} defines it as a link or block
	 */
	push(data) {
		if (this.$inProgress || !this.$ready) return;
		const state = this.state[this.index];

		if (data[0] == "autoplace" && state.mode == "autoplace") return;

		if (this.hasRedo()) {
			this.state.splice(this.index + 1, this.state.length - (this.index + 1));
		}

		this.index++;
		if (!webix.isArray(data[0])) data = [data];
		data = data.map(params =>
			webix.extend(params[1], {
				id: params[2],
				link: params[3],
				mode: params[0],
			})
		);
		this.state[this.index] = data;
		this.app.callEvent("UndoChange");
	}

	/**
	 * Сhecks the state for the possibility of undoing changes
	 */
	hasUndo() {
		return this.index > 0;
	}

	/**
	 * Сhecks the state for the possibility of redoing changes
	 */
	hasRedo() {
		return this.index + 1 < this.state.length;
	}
}
