import shapes from "shapes";
import shapeTemplates from "shapes/templates";
import { drawLine, getLinkLabelStart, checkLabelPoint } from "helpers/painter";
import { capitalize } from "helpers/styles";

export default class LocalData {
	constructor(app, data) {
		this.app = app;
		this._extraShapes = this.app.config ? this.app.config.shapes : data;
	}

	connect(view, history) {
		this._view = view;
		this._history = history;

		this._data = view.data;
		this._links = view.getLinks();
	}

	/**
	 * Return data
	 * @return {DataStore}
	 */
	data() {
		return this._data;
	}

	/**
	 * Return links
	 * @return {DataCollection}
	 */
	links() {
		return this._links;
	}

	/**
	 * Return shapes
	 * @return {DataCollection}
	 */
	shapes() {
		if (this._shapes) return this._shapes;
		this._shapes = new webix.DataCollection({
			scheme: {
				$change: obj => {
					let template = obj.template;
					if (typeof obj.square == "undefined" && typeof template == "string") {
						const baseTemplate = this.getShapeTemplate(template);
						if (baseTemplate && baseTemplate.square)
							obj.square = baseTemplate.square;
					}
					const name = typeof obj.name != "undefined" ? obj.name : obj.id;
					obj.name = capitalize(name);
				},
			},
		});

		// add default shapes to collection
		this._shapes.parse(shapes);
		this.defaultGroups = ["extra"];
		shapes.forEach(s => {
			if (this.defaultGroups.indexOf(s.group) < 0)
				this.defaultGroups.push(s.group);
		});

		if (webix.isArray(this._extraShapes)) {
			let index = -1;
			this._extraShapes.forEach(s => {
				if (s.id && this._shapes.getItem(s.id)) {
					this._shapes.updateItem(s.id, s);
				} else {
					s.group = s.group || "Extra";
					index++;
					return this._shapes.add(s, index);
				}
			});
		}
		return this._shapes;
	}

	/**
	 * Get a shape template object by its name or the hash of all templates
	 * @param name {string} a template name (optional)
	 * @returns {object|array}
	 */
	getShapeTemplate(name) {
		return name === true ? shapeTemplates : shapeTemplates[name];
	}

	/**
	 * Checks whether a shape group is default
	 * @param name {string} group name
	 * @returns {boolean} true of a shape group is default
	 */
	isDefaultGroup(name) {
		return this.defaultGroups.indexOf(name.toLowerCase()) > -1;
	}

	/**
	 * Adds single link to history state object
	 * @param link {Object} a link data object
	 * @param history {Object} history state object
	 * @param linkCache {Object} cache of already saved links
	 */
	addLinkToHistory(link, history, linkCache) {
		if (!linkCache[link.id]) {
			linkCache[link.id] = true;
			history.links.push(webix.copy(link));
		}
	}

	/**
	 * Updates all custom links associated with the block
	 * @param id {number|string} a block id
	 * @param history {Object} history state object
	 * @param linkCache {Object} cache of already saved links
	 * @param historyArray {Array} history state array
	 * @return {Boolean} something was found
	 */
	updateCustomLinks(id, history, linkCache, historyArray) {
		let render = false;
		this._links
			.find(a => a.source == id || a.target == id)
			.forEach(link => {
				if (webix.isArray(link.line)) {
					this.addLinkToHistory(link, history, linkCache);
					link.line = drawLine(this._view, link, link.from, link.to);
					render = true;
				}
				if (link.labels) this.updateLinkLabels(link.id, historyArray);
			});

		return render;
	}
	/**
	 * Shifts data and updates custom links if the block is outside the work area (x\y < 0)
	 * @param id {number|string} a block id
	 * @param history {Object} history state object
	 * @param linkCache {Object} cache of already saved links
	 * @return {Boolean} something was found
	 */
	shiftData(id, history, linkCache) {
		const item = this._data.getItem(id);

		const x = Math.min(0, item.x);
		const y = Math.min(0, item.y);
		if (x < 0 || y < 0) {
			history.data = this._data.serialize();
			this._data.each(
				obj => {
					obj.x -= x;
					obj.y -= y;
				},
				this,
				true
			);

			this._links.data.each(
				link => {
					if (webix.isArray(link.line)) {
						this.addLinkToHistory(link, history, linkCache);
						link.line = link.line.map(a => {
							a = webix.isArray(a) ? a : a.split(",");
							return [a[0] - x, a[1] - y];
						});
					}
				},
				this,
				true
			);
			return true;
		}
	}

	/**
	 * Adds new block
	 * @param obj {Object} new block data
	 * @return {number|string} id of the new block
	 */
	addBlock(obj) {
		const history = {
			value: webix.copy(obj),
			prev: null,
			links: [],
		};
		const nid = this._data.add(obj);
		history.value.id = nid;
		const hParams = [["add", history, nid]];

		if (obj.$link) {
			const link = this._links.getItem(obj.$link);
			const oldLink = webix.copy(link);
			if (!link.labels) link.labels = [];
			link.labels.push(nid);
			const history = {
				value: webix.copy(link),
				prev: oldLink,
			};
			hParams.push(["update", history, link.id, true]);
		}

		this._history.push(hParams);
		if (this.shiftData(nid, history, {})) this._view.render();
		return nid;
	}

	/**
	 * Adds new link
	 * @param obj {Object} new link data
	 * @return {number|string} id of the new link
	 */
	addLink(obj) {
		const history = {
			value: webix.copy(obj),
			prev: null,
		};

		const nid = this._links.add(obj);
		this._history.push([["add", history, nid, true]]);
		history.value.id = nid;

		return nid;
	}

	/**
	 * Removes block
	 * @param id {number|string} a block id
	 */
	removeBlock(id) {
		const item = this._data.getItem(id);
		if (!item) return;
		const history = {
			value: null,
			prev: webix.copy(item),
			links: [],
		};

		this._view.$blockRender = true;
		const hParams = [["remove", history, id]];
		this._links
			.find(a => a.source == id || a.target == id)
			.forEach(link => {
				if (link.labels) {
					link.labels.forEach(labelId => {
						const labelItem = this._data.getItem(labelId);
						const history = {
							value: null,
							prev: webix.copy(labelItem),
						};
						hParams.push(["remove", history, labelId]);
						this._data.remove(labelId);
					});
				}
				history.links.push(webix.copy(link));
				this._links.remove(link.id);
			});

		const link = item.$link ? this._links.getItem(item.$link) : null;
		if (link) {
			const oldLink = webix.copy(link);
			link.labels.splice(link.labels.indexOf(id), 1);
			if (!link.labels.length) delete link.labels;
			const history = {
				value: webix.copy(link),
				prev: oldLink,
			};
			this._links.updateItem(link.id, link);
			hParams.push(["update", history, link.id, true]);
		}
		this._view.$blockRender = false;
		this._data.remove(id);
		this._history.push(hParams);
	}

	/**
	 * Removes link
	 * @param id {number|string} a link id
	 */
	removeLink(id) {
		const item = this._links.getItem(id);
		const history = {
			value: null,
			prev: webix.copy(item),
		};
		this._links.remove(id);
		const hParams = [["remove", history, id, true]];
		if (item.labels) {
			item.labels.forEach(id => {
				const block = this._data.getItem(id);
				if (block) {
					const history = {
						value: null,
						prev: webix.copy(block),
					};
					this._data.remove(id);
					hParams.push(["remove", history, id]);
				}
			});
		}
		this._history.push(hParams);
	}

	/**
	 * Updates block data
	 * @param id {number|string} a block id
	 * @param obj {Object} object with new values
	 */
	updateBlock(id, obj) {
		const item = this._data.getItem(id);
		const prevItem = webix.copy(item);
		if (item.$link) {
			const newItem = { ...item, ...obj };
			if (newItem.linkAlign != item.linkAlign) {
				const { x, y } = getLinkLabelStart(
					this._view,
					this._links.getItem(item.$link),
					newItem
				);
				obj.x = x;
				obj.y = y;
				delete item.dx;
				delete item.dy;
			}
		}
		const history = {
			value: webix.copy(obj),
			prev: prevItem,
			links: [],
		};
		this._data.updateItem(id, obj);
		const hState = [["update", history, id]];

		const linkCache = {};
		let render = this.updateCustomLinks(id, history, linkCache, hState);
		this._history.push(hState);
		if (this.shiftData(id, history, linkCache)) render = true;
		if (render) this._view.render();
	}

	/**
	 * Updates link data
	 * @param id {number|string} a link id
	 * @param obj {Object} object with new values
	 */
	updateLink(id, obj) {
		if (obj.mode) obj.line = obj.from = obj.to = undefined;
		const linkItem = this._links.getItem(id);
		const history = {
			value: webix.copy(obj),
			prev: webix.copy(linkItem),
		};

		this._links.updateItem(id, obj);
		const hState = [["update", history, id, true]];
		if (linkItem.labels) this.updateLinkLabels(id, hState);
		this._history.push(hState);
	}

	/**
	 * Updates link labels to set their new position
	 * @param id {string} - a link id
	 * @param historyArray {Array} - an array of history state
	 */
	updateLinkLabels(id, historyArray) {
		const link = this._links.getItem(id);
		link.labels.forEach(id => {
			const block = this._data.getItem(id);
			if (block) {
				const oldBlock = webix.copy(block);
				const p = getLinkLabelStart(this._view, link, block);
				let x = p.x,
					y = p.y;
				if (block.dx || block.dy) {
					const x0 = x + (block.dx || 0);
					const y0 = y + (block.dy || 0);
					if (checkLabelPoint(this._view, [x0, y0], block, link)) {
						x = x0;
						y = y0;
					} else {
						delete block.dx;
						delete block.dy;
					}
				}
				block.x = x;
				block.y = y;
				const h = {
					value: webix.copy(block),
					prev: oldBlock,
					links: [],
				};
				this._data.updateItem(block.id, block);
				historyArray.push(["update", h, id]);
			}
		});
	}
}
