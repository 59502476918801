import simpla from "@xbs/simpla/dist/simpla.js";
import {
	drawLine,
	getLinkLabelStart,
	getPathCurvePoints,
	calcCurveArrowPoint,
} from "helpers/painter";
import { getStyle } from "./helpers/styles.js";
import LocalData from "./models/LocalData";

webix.protoUI(
	{
		name: "diagram",
		defaults: {
			zoom: 1,
			scroll: "auto",
			autoplace: true,
			treePadding: 120,
			padding: 20,
		},
		$init: function(config) {
			//used for temporary HTML elements
			this._html = document.createElement("DIV");

			this.$view.className += " webix_diagram";
			this._destroy_with_me = [];

			this.data.provideApi(this, true);
			this.serialize = this._serialize;

			this._initLinks(config.links);
			this._initShapes(config.shapes);

			config.scheme = this._setScheme(config.scheme);

			this._dataobj = this.$view.querySelector(".webix_scroll_cont");
			this._dataobj.innerHTML = `
			<div class="webix_diagram_shapes" style="position:absolute;"></div>
			<svg class="webix_diagram_links" width="100%" height="100%" style="overflow:visible"></svg>`;
			this._links_layer = this._dataobj.querySelector(".webix_diagram_links");
			this._shapes_layer = this._dataobj.querySelector(".webix_diagram_shapes");

			// wait for both collections before rendering
			this.$blockRender = true;
			webix.promise.all([this.data.waitData, this._links.waitData]).then(() => {
				this.$blockRender = false;
				this.data.callEvent("onStoreUpdated", []);
			});
			this.data.attachEvent("onStoreUpdated", () => this.render());

			//create copy of default type, and set it as active one
			if (!this.types) {
				this.types = { default: this.type };
				this.type.name = "default";
			}
			this.type = webix.clone(this.type);
			this.linkType = webix.clone(this.linkType);

			this.$ready.push(this._afterInit);
		},

		//attribute , which will be used for ID storing
		_id: /*@attr*/ "webix_dg_id",
		on_click: {
			webix_diagram_item: function(e) {
				if (this.config.select) {
					const id = this.locate(e);
					if (this.config.select == "multiselect" || this.config.multiselect)
						this.select(
							id,
							false,
							e.ctrlKey || e.metaKey || this.config.multiselect == "touch",
							e.shiftKey
						);
					//multiselection
					else this.select(id);
					return false;
				}
			},
		},
		on_context: {},
		on_dblclick: {},
		_line_modes: {
			direct: 1,
			edges: 2,
			child: 3,
			sibling: 4,
			curve: 2,
		},
		links_setter: function(data) {
			if (data && !this._skipLinksSetter) this._loadLinks(data);
			this._skipLinksSetter = false;
		},
		_afterInit: function() {
			const c = this.config;
			this._dataobj.style.position = "relative";
			this._dataobj.style.margin = `${c.paddingY || c.padding}px ${c.paddingX ||
				c.padding}px`;
			this.attachEvent("onDestruct", function() {
				this._dataobj = this._html = this._htmlmap = this._links_layer = this._shapes_layer = null;
				this._shapes = this._links = this._links_map = this._shapeTemplates = null;

				for (let i = 0; i < this._destroy_with_me.length; i++)
					this._destroy_with_me[i].destructor();
			});
		},

		getItemNode: function(searchId) {
			if (this._htmlmap) return this._htmlmap[searchId];

			//fill map if it doesn't created yet
			this._htmlmap = {};

			const t = this._shapes_layer.childNodes;
			for (let i = 0; i < t.length; i++) {
				let id = t[i].getAttribute(this._id);
				if (id) this._htmlmap[id] = t[i];
			}

			//call locator again, when map is filled
			return this.getItemNode(searchId);
		},

		locate: function(e) {
			return webix.html.locate(e, this._id);
		},

		_toHTMLObject: function(obj) {
			this._html.innerHTML = this._toHTMLItem(obj);
			return this._html.firstChild;
		},

		//autowidth, autoheight - no inner scroll
		//scrollable - width, height, auto, with scroll
		$getSize: function(dx, dy) {
			const c = this.config;
			if (c.autowidth) {
				dx =
					this._dataobj.offsetWidth +
					(c.paddingX || c.padding) +
					(this._dataobj.offsetHeight > dy && !c.autoheight
						? webix.env.scrollSize
						: 0);
			}
			if (c.autoheight) {
				dy =
					this._dataobj.offsetHeight +
					2 * (c.paddingY || c.padding) +
					(this._dataobj.offsetWidth > dx && !c.autowidth
						? webix.env.scrollSize
						: 0);
			}
			return webix.ui.baseview.prototype.$getSize.call(this, dx, dy);
		},

		$setSize: function(x, y) {
			if (webix.ui.baseview.prototype.$setSize.call(this, x, y)) this.render();
		},

		_set_dataobj_size: function(box) {
			const c = this.config;
			let width = box[0][1];
			let height = box[1][1];
			width =
				c.zoom > 1
					? width + (c.paddingX || c.padding) / c.zoom
					: width * c.zoom;
			if (!c.autoheight)
				height =
					c.zoom > 1
						? height + (c.paddingY || c.padding) / c.zoom
						: height * c.zoom;
			this._dataobj.style.width = width + "px";
			this._dataobj.style.height = height + "px";
			this._dataobj.style.transform = "scale(" + c.zoom + ")";
		},

		linkItem_setter: function(value) {
			this.linkType_setter(value);
		},

		linkType_setter: function(value) {
			if (value && typeof value == "object")
				for (let key in value) {
					if (key.indexOf("template") === 0)
						this.linkType[key] = webix.template(value[key]);
					else this.linkType[key] = value[key];
				}
		},

		_link_id: /*@attr*/ "webix_dg_link_id",
		_arrow_id: /*@attr*/ "webix_dg_arrow_id",
		_shadow_id: /*@attr*/ "webix_dg_shadow_id",

		getLinkItemNode: function(id, mode) {
			if (!this._links_map) this._links_map = {};

			let nodes;
			if (this._links_map[id]) nodes = this._links_map[id];
			else {
				const layer = this._links_layer;
				nodes = this._links_map[id] = {
					link: layer.querySelector(`[${this._link_id}="${id}"]`),
					arrow: layer.querySelector(`[${this._arrow_id}="${id}"]`),
				};
				nodes.shadow = this.linkType.shadow
					? layer.querySelector(`[${this._shadow_id}="${id}"]`)
					: null;
			}

			return mode ? nodes[mode] : nodes;
		},

		linkType: {
			css: "",
			mode: "edges",
			shadow: false,
			arrow: false,
			arrowCss: "",
			arrowSize: 6,
			classname: function(obj, common, field) {
				let css = field == "css" ? "webix_diagram_link" : "webix_diagram_arrow";

				if (common[field]) css += " " + common[field];

				field = "$" + field;
				if (obj[field]) {
					if (typeof obj[field] == "object")
						obj[field] = webix.html.createCss(obj[field]);
					css += " " + obj[field];
				}

				const cssMark = this.getLinks().data.getMark(obj.id, "$css");
				if (cssMark) css += cssMark;

				return css;
			},
			getStyle: (o, m, c) => getStyle(o, m, c),
			templateLine: function(obj) {
				if (!obj.line || !obj.line.length) return "";

				const diff = obj.diff || 0;
				const points = obj.line
					.map(a => {
						return webix.isArray(a)
							? [Math.ceil(a[0] + diff), Math.ceil(a[1] + diff)].join(",")
							: a;
					})
					.join(" ");

				const type = obj.type || "polyline";
				return `<${type} ${obj.attrs || ""} points="${points}" ${
					obj.css ? `class="${obj.css}" ` : ""
				} ${obj.style ? `style="${obj.style}"` : ""}/>`;
			},
			templateCurve: function(obj) {
				if (!obj.line || !obj.line.length) return "";
				const line = obj.line.map(a => (webix.isArray(a) ? a : a.split(",")));
				const { d, points } = getPathCurvePoints(line);
				let lastPoint = line[line.length - 1].join(",");
				return `<path ${obj.attrs ||
					""} d="${d}" data-points="${points}"  data-point="${lastPoint}"${
					obj.css ? `class="${obj.css}" ` : ""
				} ${obj.style ? `style="${obj.style}"` : ""}/>`;
			},
			templateShadow: function(obj, common, line) {
				if (webix.isArray(obj.line)) line = obj.line;
				const cssMark = this.getLinks().data.getMark(obj.id, "$css") || "";
				const config = {
					line,
					diff: common.shadow / 2,
					attrs: `${this._shadow_id}="${obj.id}"`,
					css: "webix_diagram_link_shadow" + cssMark,
					style: `stroke-width:${(obj.lineWidth || 1) + common.shadow};`,
				};
				if (this.isCurveLink(obj))
					return common.templateCurve.call(this, config, common);
				return common.templateLine.call(this, config, common);
			},
			templateLink: function(obj, common, line) {
				if (webix.isArray(obj.line)) line = obj.line;
				const config = {
					line,
					attrs: `${this._link_id}="${obj.id}"`,
					css: common.classname.call(this, obj, common, "css"),
					style: common.getStyle.call(this, obj, "line", common),
				};
				if (this.isCurveLink(obj))
					return common.templateCurve.call(this, config, common);
				return common.templateLine.call(this, config, common);
			},
			templateArrow: function(obj, common, line) {
				if (webix.isArray(obj.arrow)) line = obj.arrow;

				const config = {
					line,
					attrs: `${this._arrow_id}="${obj.id}"`,
					css: common.classname.call(this, obj, common, "arrowCss"),
					style: common.getStyle.call(this, obj, "arrow", common),
				};

				const arrow = webix.isUndefined(obj.arrow) ? common.arrow : obj.arrow;
				if (arrow == "triangle") config.type = "polygon";

				return common.templateLine.call(this, config, common);
			},
		},
		autoPlace: function(root) {
			if (!this.config.autoplace) {
				this._links.data.each(l => {
					["line", "from", "to"].forEach(e => delete l[e]);
					if (webix.isArray(l.arrow)) l.arrow = true;
				});
				this._renderGraph(true, root);
			}
		},

		template_setter: function(value) {
			this.type.template = webix.template(value);
		},

		customize: function(obj) {
			webix.type(this, obj);
		},

		item_setter: function(value) {
			return this.type_setter(value);
		},

		type_setter: function(value) {
			if (!this.types[value]) this.customize(value);
			else {
				this.type = webix.clone(this.types[value]);
				if (this.type.css) this.$view.className += " " + this.type.css;
			}
			if (this.type.on_click) webix.extend(this.on_click, this.type.on_click);

			return value;
		},

		type: {
			width: 100,
			height: 40,
			margin: 20,
			marginX: 0,
			marginY: 0,
			listMarginX: 20,
			type: "default",
			classname: function(obj, common) {
				let css = "webix_diagram_item";

				let customCss = this.getItemValue(obj.id, "$css");
				if (customCss) {
					if (typeof customCss == "object")
						customCss = webix.html.createCss(customCss);
					css += " " + customCss;
				}
				const type = obj.type || common.type || "default";
				const shape = this.getShape(type);
				const base =
					shape && shape.template && this._shapeTemplates[shape.template];
				if (base && type != shape.template)
					css += " webix_diagram_item_" + shape.template;
				css += " webix_diagram_item_" + type;

				if (common.css)
					if (typeof common.css == "function") {
						css += " " + common.css.call(this, obj, common);
					} else css += " " + common.css;
				const cssMark = this.data.getMark(obj.id, "$css");
				if (cssMark) css += cssMark;

				return css;
			},
			getStyle: function(o, m, c) {
				const shape = this.getShape(o.type || c.type);

				return getStyle(o, m, shape);
			},
			template: function(o) {
				return o.value || "";
			},
			templateShape: function(obj, common) {
				let shape = this.getShape(obj.type || common.type);

				let shapeHTML = "",
					style;
				if (shape && shape.template) {
					let template = shape.template;
					const baseTemplate = this._shapeTemplates[template];
					if (baseTemplate) template = baseTemplate.template;
					if (typeof template == "function") {
						let shapeObj = {
							height: common.height,
							width: common.width - (obj.$list ? common.listMarginX : 0),
							...shape,
						};
						delete shapeObj.name;
						shapeHTML = template({ ...shapeObj, ...obj });
					} else if (template) shapeHTML = template;
					let svg = (baseTemplate || shape).svg;
					if (typeof svg == "undefined")
						svg = shapeHTML.toLowerCase().includes("<svg");

					if (svg) {
						style = common.getStyle.call(this, obj, "svg", common);
						if (style)
							shapeHTML = shapeHTML.replace(
								/(<\s*svg)/i,
								"$1 style='" + style + "'"
							);

						style = common.getStyle.call(this, obj, "alt", common);
						if (style)
							shapeHTML = shapeHTML.replace(
								/(class='webix_diagram_shape_alt')/gi,
								"$1 style='" + style + "'"
							);
					} else {
						const styleGroup =
							shapeHTML.indexOf("webix_diagram_shape_text") != -1
								? "textBlock"
								: "block";
						style = common.getStyle.call(this, obj, styleGroup, common);
						if (style)
							shapeHTML = shapeHTML.replace(
								/(<\s*\w+)/i,
								"$1 style='" + style + "'"
							);
					}
				}
				return shapeHTML;
			},
			templateTextStart: function(obj, common) {
				return (
					"<div class='webix_diagram_text' style='" +
					common.getStyle.call(this, obj, "text", common) +
					"'><span class='webix_diagram_text_inner'>"
				);
			},
			templateStart: function(obj, common) {
				const size = this._getItemSize(obj, true);
				const correction = obj.$list ? common.listMarginX : 0;

				let style = `width:${size.width}px;height:${size.height}px;`;
				style += `top:${obj.y * 1}px;left:${obj.x * 1 + correction}px;`;
				style += common.getStyle.call(this, obj, "rotation", common);

				return (
					"<div " +
					/*@attr*/ "webix_dg_id" +
					`="${obj.id}" class="${common.classname.call(
						this,
						obj,
						common
					)}" style="${style}">` +
					common.templateShape.call(this, obj, common) +
					common.templateTextStart.call(this, obj, common)
				);
			},
			templateTextEnd: webix.template("</span></div>"),
			templateEnd: webix.template("{common.templateTextEnd()}</div>"),
		},

		_initLinks: function(value) {
			if (value) this._skipLinksSetter = true;
			if (value && value.getItem) {
				this._links = value;
			} else {
				this._links = new webix.DataCollection({
					scheme: this._setScheme(),
				});
				this._destroy_with_me.push(this._links);
				this._loadLinks(value);
			}
			this._links.data.attachEvent("onStoreUpdated", () => this.render());
		},
		_loadLinks(value) {
			if (value && typeof value === "string")
				this._links.load(value, this.config.datatype);
			else this._links.parse(value || [], this.config.datatype);
		},
		getLinks: function() {
			return this._links;
		},
		_toHTMLItem: function(obj) {
			this.callEvent("onItemRender", [obj]);
			return (
				this.type.templateStart.call(this, obj, this.type) +
				this.type.template.call(this, obj, this.type) +
				this.type.templateEnd.call(this, obj, this.type)
			);
		},

		render: function(id, obj, mode) {
			if (!this.isVisible(this.config.id) || this.$blockRender) return;

			if (mode == "update") {
				// selection
				const cont = this.getItemNode(id); //get html element of updated item
				const t = (this._htmlmap[id] = this._toHTMLObject(obj));
				webix.html.insertBefore(t, cont);
				webix.html.remove(cont);
				return true;
			} //full reset
			else this._renderGraph();

			return true;
		},

		_renderGraph: function(auto, root) {
			const config = {
				full: true,
				root: root || this.config.root,
				preserveLocation: auto ? false : !this.config.autoplace,
				itemPadding: this.type.marginX || this.type.margin,
			};

			if (this.callEvent("onBeforeRender", [this.data])) {
				this._htmlmap = this._links_map = null;

				const graph = this._buildGraph(config);
				graph.clean();

				const routes = this._layoutGraph(graph, config);
				this._showGraph(routes[0], routes[1], config);

				const box = routes[0].getNodes().length
					? routes[0].getBox()
					: [[0, 0], [0, 0]];
				this._set_dataobj_size(box);

				this.callEvent("onAfterRender", []);
			}
		},

		getItemValue: function(id, field) {
			const obj = this.getItem(id);
			field = field || "value";

			let value = obj[field];
			if (webix.isUndefined(value)) {
				const shape = this.getShape(obj.type || this.type.type) || {};
				value = shape[field];
			}
			return value;
		},

		_getItemSize: function(obj, correct) {
			let typeWidth = this.type.width;
			if (correct && obj.$list) typeWidth -= this.type.listMarginX;

			const width = obj.width === "auto" ? obj.width : obj.width * 1;
			const height = obj.height === "auto" ? obj.height : obj.height * 1;

			const shape = this.getShape(obj.type || this.type.type) || {};
			return {
				width: width || shape.width || typeWidth,
				height: height || shape.height || this.type.height,
			};
		},

		adjustItem: function(id, field) {
			field = field == "width" ? field : "height";

			const obj = this.data.getItem(id);
			obj[field] = "auto";

			this._calcItemSize(obj);
			this.data.updateItem(id);
		},

		_calcItemSize: function(obj) {
			const shape = this.getShape(obj.type || this.type.type) || {};
			const r = this._getItemSize(obj);

			if (r.width == r.height && r.width == "auto") {
				// save size on data obj
				if (!webix.isUndefined(shape.height) && shape.height != "auto")
					obj.height = r.height = shape.height;
				else if (this.type.height != "auto")
					obj.height = r.height = this.type.height;
				else obj.height = r.height = this.type.__proto__.height;
			}

			const field =
				r.width == "auto" ? "width" : r.height == "auto" ? "height" : null;
			if (field) {
				const d = webix.html.create("DIV", {
					class:
						"webix_view webix_diagram_measure_size " +
						this.type.classname.call(this, obj, this.type),
					style: `visibility:hidden;position:absolute;top:0px;left:0px;width:${r.width}px;height:${r.height}px`,
				});
				d.style[field] = "auto";
				document.body.appendChild(d);

				const text = this.type.template.call(this, obj, this.type);
				if (text)
					d.innerHTML =
						this.type.templateTextStart.call(this, obj, this.type) +
						text +
						this.type.templateTextEnd.call(this, obj, this.type);
				else d.innerHTML = this.type.templateShape.call(this, obj, this.type);

				// save size - so that the calculation does not start over
				obj[field] = r[field] =
					d[field == "width" ? "offsetWidth" : "offsetHeight"] + 1;

				webix.html.remove(d);
			}

			return r;
		},

		_buildGraph: function(config) {
			const graph = new simpla.Graph();

			let maxSize = 0;
			this.data.each(a => {
				const size = this._calcItemSize(a);
				const n = {
					id: String(a.id),
					w: size.width,
					h: size.height,
				};
				maxSize = Math.max(maxSize, size.width, size.height);

				if (config.preserveLocation)
					webix.extend(n, {
						x: a.x * 1 + n.w / 2,
						y: a.y * 1 + n.h / 2,
					});
				graph.addNode(n);
				delete a.$list;
			});
			config.levelPadding = maxSize + (this.type.marginY || this.type.margin);

			this._links.data.each(a => {
				if (graph.hash[a.source] && graph.hash[a.target])
					graph.addEdge(a.source, a.target);
			});
			return graph;
		},

		_layoutGraph: function(graph, config) {
			let g;
			if (!config.preserveLocation) {
				const l = new simpla.Hola(config);
				g = simpla.compose(
					simpla.decompose(graph).map(g => {
						g = l.layout(g, config);
						if (!config.full) g.setGlobalBox();
						return g;
					}),
					{ padding: this.config.treePadding } //padding between trees
				);
			} else {
				g = graph;
				g.setGlobalBox();
			}

			if (!g) return [graph, []];

			if (!config.preserveLocation) {
				const bounds = g.getBox();
				g.translate({ x: -bounds[0][0], y: -bounds[1][0] });
			}

			const router = new simpla.Router({
				mode: this._line_modes[this.linkType.mode] == 1 ? 1 : 2,
				padding: this.type.listMarginX / 2,
			});
			g.nodes.forEach(a => {
				return router.addShape(
					String(a.id),
					a.x - a.w / 2,
					a.y - a.h / 2,
					a.x + a.w / 2,
					a.y + a.h / 2
				);
			});
			this._links.data.each(a => {
				if (g.hash[a.source] && g.hash[a.target]) {
					router.addConnector(
						String(a.id),
						String(a.source),
						String(a.target),
						this._line_modes[a.mode]
					);
					if (this._line_modes[a.mode] == 4) {
						this.data.getItem(a.source).$list = true;
						this.data.getItem(a.target).$list = true;
					} else if (this._line_modes[a.mode] == 3) {
						this.data.getItem(a.target).$list = true;
					}
				}
			});

			return [g, router.finalize(simpla.Direction.Bottom)];
		},

		_showGraph: function(graph, routes, config) {
			let html = "";
			this.data.each(obj => {
				if (!config.preserveLocation) {
					const n = graph.getNode(obj.id);
					obj.x = n.x - n.w / 2;
					obj.y = n.y - n.h / 2;
				}
				html += this._toHTMLItem(obj);
			});
			this._shapes_layer.innerHTML = html;

			html = "";
			// render links
			routes.forEach(r => {
				const obj = this._links.getItem(r.id);
				if (
					(obj.from || obj.to || this.isCurveLink(obj)) &&
					!webix.isArray(obj.line)
				) {
					r.c_line = r.line = drawLine(this, obj, obj.from, obj.to);
				}
				if (this.linkType.shadow) {
					html += this.linkType.templateShadow.call(
						this,
						obj,
						this.linkType,
						r.line
					);
				}
				html += this.linkType.templateLink.call(
					this,
					obj,
					this.linkType,
					r.line
				);
				if (obj.labels) this._arrangeLinkLabel(obj, r);
			});
			// and only then arrows
			routes.forEach(r => {
				const obj = this._links.getItem(r.id);
				const arrow = webix.isUndefined(obj.arrow)
					? this.linkType.arrow
					: obj.arrow;

				if (arrow) {
					const config = { correction: 1, width: 0, height: 0, mode: null };
					let line = r.line;
					if (webix.isArray(obj.line) && obj.line.length > 1) {
						config.correction = 0;
						line = obj.line.map(a => {
							a = webix.isArray(a) ? a : a.split(",");
							return a.map(b => b * 1);
						});
					} else if (r.c_line) config.correction = 0;

					let index = line.length;
					let p2 = line[--index],
						p1 = line[--index];

					// check curve line
					if (this.isCurveLink(obj)) {
						config.mode = "curve";

						p1 = calcCurveArrowPoint(line[index - 1], p1, p2);
					}

					if (config.correction) {
						const target = this.getItem(obj.target);
						webix.extend(config, this._getItemSize(target, true), true);

						while (p1 && !this._check_point(p1, target, config)) {
							p2 = p1;
							p1 = line[--index];
						}
					}
					config.x = p2[0];
					config.y = p2[1];

					if (!p1) config.mode = null;
					else if (
						config.mode == "curve" &&
						Math.round(p1[0]) != Math.round(p2[0])
					) {
						config.x0 = p1[0];
						config.y0 = p1[1];
					} else if (p1[0] == p2[0]) {
						config.mode = "height";
						config.k = p1[1] < p2[1] ? -1 : 1;
					} else if (p1[1] == p2[1]) {
						config.mode = "width";
						config.k = p1[0] < p2[0] ? -1 : 1;
					} else {
						config.mode = "direct";
						config.x0 = p1[0];
						config.y0 = p1[1];
					}

					html += this.linkType.templateArrow.call(
						this,
						obj,
						this.linkType,
						config.mode && this._get_arrow(obj, config)
					);
				}
			});
			this._links_layer.innerHTML = html;
		},

		_check_point: function(p, obj, c) {
			return (
				p[0] < obj.x ||
				p[0] > obj.x + c.width ||
				p[1] < obj.y ||
				p[1] > obj.y + c.height
			);
		},

		_get_arrow: function(obj, c) {
			const h = obj.arrowSize || this.linkType.arrowSize;
			const a = Math.ceil(h / Math.sqrt(3));
			if (c.mode == "height") {
				c.y += (c.correction * c.k * c[c.mode]) / 2;
				return [[c.x - a, c.y + c.k * h], [c.x, c.y], [c.x + a, c.y + c.k * h]];
			} else if (c.mode == "width") {
				c.x += (c.correction * c.k * c[c.mode]) / 2;
				return [[c.x + c.k * h, c.y - a], [c.x, c.y], [c.x + c.k * h, c.y + a]];
			} else if (c.mode == "direct" || c.mode == "curve") {
				let w0, h0, angle, tanA, x0, y0, x1, y1, x2, y2, k, kX, kY;
				h0 = c.height / 2;
				tanA = Math.abs(c.x - c.x0) / (Math.abs(c.y - c.y0) || 1);
				w0 = tanA * h0;
				x0 = c.x;
				y0 = c.y;
				if (c.mode == "direct") {
					x0 += w0 * (c.x < c.x0 ? 1 : -1);
					y0 += h0 * (c.y < c.y0 ? 1 : -1);
				}

				k = c.y0 < c.y ? -1 : 1;
				x1 = -a;
				y1 = k * h;
				x2 = a;
				y2 = k * h;
				angle = Math.atan(tanA);
				kX = k * (c.x < c.x0 ? 1 : -1);
				kY = k * (c.x < c.x0 ? -1 : 1);
				if (c.mode == "direct") {
					if (x0 < c.x - c.width / 2) {
						x0 = c.x - c.width / 2;
						y0 = c.y + ((k * c.width) / 2) * Math.tan(Math.PI / 2 - angle);
					} else if (x0 > c.x + c.width / 2) {
						x0 = c.x + c.width / 2;
						y0 = c.y + ((k * c.width) / 2) * Math.tan(Math.PI / 2 - angle);
					} else if (!obj.line) {
						// 1px offset
						y0 += c.y < c.y0 ? 1 : -1;
						x0 += (c.x < c.x0 ? 1 : -1) * tanA;
					}
				}
				// x1=x*cos(a)-y*sin(a); y1=y*cos(a)+x*sin(a);
				return [
					[
						x0 + x1 * Math.cos(angle) + kX * y1 * Math.sin(angle),
						y0 + y1 * Math.cos(angle) + kY * x1 * Math.sin(angle),
					],
					[x0, y0],
					[
						x0 + x2 * Math.cos(angle) + kX * y2 * Math.sin(angle),
						y0 + y2 * Math.cos(angle) + kY * x2 * Math.sin(angle),
					],
				];
			}
		},
		_arrangeLinkLabel(link, route) {
			if (typeof link.labels == "string")
				link.labels = link.labels.split(",").map(id => id.trim());
			const updatedLabels = [];
			link.labels.forEach(id => {
				let label = this.getItem(id);
				if (label) {
					updatedLabels.push(id);
					label.$link = link.id;
					label.type = label.type || "label";
					const pos = getLinkLabelStart(
						this,
						{ ...link, line: route.line },
						label
					);
					label.x = pos.x + (label.dx || 0);
					label.y = pos.y + (label.dy || 0);
					this.render(id, label, "update");
				}
			});
			link.labels = updatedLabels;
		},
		// SHAPES
		_initShapes: function(arr) {
			let ldata;
			if (
				this.$scope &&
				this.$scope.app &&
				this.$scope.app.config.view === "diagram-editor"
			) {
				ldata = this.$scope.app.getService("local");
				this._shapes = ldata.shapes();
			} else {
				ldata = new LocalData({}, arr);
				this._shapes = ldata.shapes();
				this._destroy_with_me.push(this._shapes);
			}
			this._shapeTemplates = ldata.getShapeTemplate(true);
		},
		addShape: function(name, obj) {
			if (!this.getShape(name)) {
				obj.id = name;
				obj.name = name;
				return this._shapes.add(obj);
			}
			return null;
		},
		setShape: function(name, settings) {
			const shape = this.getShape(name);
			if (shape) this._shapes.updateItem(name, settings);
		},
		getShape: function(name) {
			return this._shapes.getItem(name);
		},
		getShapes: function() {
			return this._shapes;
		},
		_serialize: function(all) {
			if (all) {
				return {
					data: this.data.serialize(),
					shapes: this._shapes.serialize(),
					links: this._links.serialize(),
					item: webix.copy(this.type),
					linkItem: webix.copy(this.linkType),
				};
			} else {
				return this.data.serialize();
			}
		},
		$onLoad: function(obj) {
			if (obj.data) {
				//from editor
				if (obj.shapes) this._shapes.parse(obj.shapes);
				if (obj.links) this._links.parse(obj.links);
				webix.extend(this.type, obj.item || {}, true);
				webix.extend(this.linkType, obj.linkItem || {}, true);
			}
			return false; //continue parsing blocks to store
		},
		_exportScheme: function(obj) {
			const result = {};
			for (let i in obj) {
				if (i.indexOf("$") < 0 || i == "$link") {
					if (webix.isUndefined(obj[i])) continue;
					else if (webix.isArray(obj[i])) result[i] = webix.copy(obj[i]);
					else result[i] = obj[i];
				}
			}
			return result;
		},
		_setScheme: function(scheme) {
			if (!scheme) scheme = {};
			scheme.$serialize = scheme.$serialize || this._exportScheme;
			scheme.$export = scheme.$export || this._exportScheme;
			return scheme;
		},
		isCurveLink: function(obj) {
			return (obj.mode || this.linkType.mode) == "curve";
		},
	},
	webix.AutoTooltip,
	webix.DataMarks,
	webix.SelectionModel,
	webix.MouseEvents,
	webix.Scrollable,
	webix.DataLoader,
	webix.ui.baseview,
	webix.EventSystem
);
