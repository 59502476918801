function drawDefaultLine(view, source, target, link) {
	const mode = link.mode || view.linkType.mode;

	const s = getPoint(view, source, "center");
	const e = getPoint(view, target, "center");

	let result;
	if (mode == "direct" || s[1] == e[1]) {
		result = [[s[0], s[1]], [e[0], e[1]]];
		if (mode == "curve") {
			const dx = Math.floor((e[0] - s[0]) / 2);
			const dx0 = Math.floor((e[0] - s[0]) / 8);
			result.splice(1, 0, [s[0] + dx - dx0, s[1]], [s[0] + dx + dx0, s[1]]);
		}
	} else {
		const y = s[1] + Math.floor((e[1] - s[1]) / 2);
		if (mode == "curve" && s[0] == e[0]) {
			const dy0 = Math.floor((e[1] - s[1]) / 8);
			result = [[s[0], s[1]], [s[0], y - dy0], [e[0], y + dy0], [e[0], e[1]]];
		} else result = [[s[0], s[1]], [s[0], y], [e[0], y], [e[0], e[1]]];
	}
	if (mode == "curve")
		result = calcCurvePoints(view, result, link, source, target);
	return result;
}

function drawCustomLine(view, source, target, link) {
	const result = webix.copy(link.line);
	if (link.from) result[0] = getPoint(view, source, link.from);
	if (link.to) result[result.length - 1] = getPoint(view, target, link.to);
	return result;
}

function isVertical(from, to) {
	return from != "left" && from != "right" && to != "left" && to != "right";
}

function isHorisontal(from, to) {
	return from != "top" && from != "bottom" && to != "top" && to != "bottom";
}

function getAngle(view, item) {
	let angle = item["angle"];
	if (webix.isUndefined(angle)) angle = view.getItemValue(item.id, "angle");
	return 1 * angle || 0;
}

const rotation = {
	right: 0,
	bottom: 90,
	left: 180,
	top: 270,
};

function correctAngle(angle, mode) {
	return (((angle + rotation[mode]) % 360) * Math.PI) / 180;
}

function correctDirection(angle, mode) {
	angle = (angle + rotation[mode]) % 360;
	const dirr = Math.floor((angle - 45) / 90);
	return (dirr < 3 ? dirr : 0) + 2;
}

function shiftPoint(p, mode, angle, diff) {
	if (angle) {
		angle = correctAngle(angle, mode);
		return [
			Math.round(p[0] + diff * Math.cos(angle)),
			Math.round(p[1] + diff * Math.sin(angle)),
		];
	} else if (mode == "left") {
		return [p[0] - diff, p[1]];
	} else if (mode == "top") {
		return [p[0], p[1] - diff];
	} else if (mode == "bottom") {
		return [p[0], p[1] + diff];
	} else if (mode == "right") {
		return [p[0] + diff, p[1]];
	}
}

function updateRoute(route, s, e, from, to) {
	// one of the points is the center
	if (from * to == 0) {
		if (from == 2 || from == 4 || to == 1 || to == 3) [s, e] = [e, s];
		return route.push([s[0], e[1]]);
	}
	// the same sides
	else if (from == to) {
		if ((from == 1 && s[0] < e[0]) || (from == 3 && s[0] > e[0])) {
			return route.push([e[0], s[1]]);
		} else if ((from == 2 && s[1] > e[1]) || (from == 4 && s[1] < e[1])) {
			return route.push([e[0], s[1]]);
		}
	}
	// opposite sides
	else if (Math.abs(from - to) == 2) {
		if ((from == 1 && s[0] > e[0]) || (from == 3 && s[0] < e[0])) {
			const p = (s[1] + e[1]) / 2;
			return route.push([s[0], p], [e[0], p]);
		} else if ((from == 2 && s[1] > e[1]) || (from == 4 && s[1] < e[1])) {
			const p = (s[0] + e[0]) / 2;
			return route.push([p, s[1]], [p, e[1]]);
		}
	}
	// perpendicular sides
	else if (from == 1 && s[0] < e[0]) {
		if ((to == 2 && s[1] > e[1]) || (to == 4 && s[1] < e[1]))
			return route.push([e[0], s[1]]);
	} else if (from == 3 && s[0] > e[0]) {
		if ((to == 2 && s[1] > e[1]) || (to == 4 && s[1] < e[1]))
			return route.push([e[0], s[1]]);
	} else if (from == 2) {
		if (to == 3 && (s[0] > e[0] || s[1] > e[1]))
			return route.push([e[0], s[1]]);
		else if (to == 1 && !(s[0] >= e[0] && s[1] <= e[1]))
			return route.push([e[0], s[1]]);
	} else if (from == 4) {
		if (to == 1 && (s[0] < e[0] || s[1] < e[1]))
			return route.push([e[0], s[1]]);
		else if (to == 3 && !(s[0] <= e[0] && s[1] >= e[1]))
			return route.push([e[0], s[1]]);
	}

	// others
	return route.push([s[0], e[1]]);
}

/**
 * Calculates the path of the line
 * @param view {webix view} diagram
 * @param link {Object} link data object
 * @param from {string} defines the starting side
 * @param to {string} defines the end side
 * @param item {Object} custom block data for timelines
 * @return {Array}
 */
export function drawLine(view, link, from, to, item) {
	let source = view.getItem(link.source);
	let target = view.getItem(link.target);

	if (item && source.id == item.id) source = { ...source, ...item };
	else if (item && target.id == item.id) target = { ...target, ...item };

	from = from || "center";
	to = to || "center";
	if (webix.isArray(link.line))
		return drawCustomLine(view, source, target, link);
	else if (from == "center" && to == "center")
		return drawDefaultLine(view, source, target, link);

	const isCurve = view.isCurveLink(link);
	let s = getPoint(view, source, from); // start point
	let e = getPoint(view, target, to); // end point

	const sa = getAngle(view, source); // source angle
	const ta = getAngle(view, target); // target angle
	if (!isCurve && !sa && !ta) {
		// straight horizontal
		if (s[1] == e[1] && isHorisontal(from, to, sa, ta)) return [s, e];
		// straight vertical
		else if (s[0] == e[0] && isVertical(from, to, sa, ta)) return [s, e];
	}

	const sroute = [s];
	if (from != "center") {
		sroute.push((s = shiftPoint(s, from, sa, view.type.listMarginX / 2)));
		from = correctDirection(sa, from);
	} else from = 0;

	const eroute = [e];
	if (to != "center") {
		eroute.unshift((e = shiftPoint(e, to, ta, view.type.listMarginX / 2)));
		to = correctDirection(ta, to);
	} else to = 0;

	if (s[0] != e[0] && s[1] != e[1]) updateRoute(sroute, s, e, from, to);

	let result = sroute.concat(eroute);
	if (isCurve)
		result = calcCurvePoints(view, result, link, source, target, from, to);
	return result;
}
/**
 * Calculates curve points based on line points
 * @param view {webix view} diagram
 * @param points {array} line points to convert
 * @param link {object} a link object
 * @param source {object} a source item object
 * @param target {object} a target item object
 * @param from {string} the side where the link starts
 * @param to {string} the end side
 * @return {Array}
 */
export function calcCurvePoints(view, points, link, source, target, from, to) {
	const sides = ["center", "right", "bottom", "left", "top"];
	if (sides[from]) from = sides[from];
	if (sides[to]) to = sides[to];
	if (!from) from = getIntersectionSide(view, source, points, 1);
	if (!to) to = getIntersectionSide(view, target, points, -1);

	let s = getPoint(view, source, from); // start point
	let e = getPoint(view, target, to); // end point

	let p = [s],
		correction;
	for (let i = 1; i < points.length - 1; i++) {
		let p0 = points[i];
		let p1 = points[i + 1];
		if (i != points.length - 2) p1 = getMidPoint(p0, p1);
		else p1 = e;
		if (i == 1 || i == points.length - 2) {
			const item = i == 1 ? source : target;
			const side = i == 1 ? from : to;
			if (!checkPoint(p0, item, getItemSize(view, item))) {
				correction = true;
				const pm = getMidPoint(p[p.length - 1], p1);
				if (side == "left" || side == "right") p0[0] = pm[0];
				else p0[1] = pm[1];
			}
		}

		p.push(p0, p1);
	}
	if (correction) p[2] = getMidPoint(p[1], p[3]);
	return p;
}

/**
 * Get the name of the side where a curve intersects a block
 * @param view {webix view} diagram
 * @param item {Object} block data object
 * @param points {Array} an array or curve line points
 * @param dir {number} 1 or -1 ( 1 for link intersection with a source item, -1 for target intersection)
 * @returns {string} the name of the side (ex. "left")
 */
function getIntersectionSide(view, obj, points, dir) {
	const item = view.getItem(obj.id);
	const size = getItemSize(view, item);
	let s = "bottom";
	let index = dir > 0 ? 0 : points.length - 1;
	let p = points[index + dir];
	while (points[index] && !checkPoint(p, obj, size)) {
		p = points[index];
		index += dir;
	}
	if (obj.x >= p[0]) s = "left";
	else if (obj.x + size.width <= p[0]) s = "right";
	else if (obj.y >= p[1]) s = "top";
	return s;
}

function checkPoint(p, obj, size) {
	return (
		p[0] <= obj.x ||
		p[0] >= obj.x + size.width ||
		p[1] <= obj.y ||
		p[1] >= obj.y + size.height
	);
}

function getItemSize(view, obj) {
	const shape = view.getShape(obj.type || view.type.type) || {};
	return {
		width: obj.width * 1 || shape.width || view.type.width,
		height: obj.height * 1 || shape.height || view.type.height,
	};
}

/**
 * Returns the position of a point in the format [x,y]
 * @param view {webix view} diagram
 * @param obj {Object} block data object
 * @param mode {string} defines the side
 * @param diff {number} additional offset
 * @return {Array}
 */
export function getPoint(view, obj, mode, diff) {
	const size = getItemSize(view, obj);
	diff = diff || 0;

	const cx = 1 * obj.x + size.width / 2;
	const cy = 1 * obj.y + size.height / 2;
	let angle = getAngle(view, obj);

	if (mode == "center") {
		return [cx, cy];
	} else if (angle) {
		const r =
			(mode == "left" || mode == "right" ? size.width : size.height) / 2 + diff;
		angle = correctAngle(angle, mode);
		return [
			Math.round(cx + r * Math.cos(angle)),
			Math.round(cy + r * Math.sin(angle)),
		];
	} else if (mode == "left") {
		return [1 * obj.x - diff, cy];
	} else if (mode == "top") {
		return [cx, 1 * obj.y - diff];
	} else if (mode == "bottom") {
		return [cx, 1 * obj.y + size.height + diff];
	} else if (mode == "right") {
		return [1 * obj.x + size.width + diff, cy];
	}
}

export function getLinkLabelStart(view, link, item, movedItem) {
	let p,
		line = link.line,
		from = link.from,
		to = link.to;
	if (!webix.isArray(line)) line = drawLine(view, link, from, to);
	else line = line.map(a => (webix.isArray(a) ? a : a.split(",")));
	let pos;
	const { width, height } = item ? getItemSize(view, item) : view.type;
	if (item && (item.linkAlign == "start" || item.linkAlign == "end")) {
		const sides = ["center", "right", "bottom", "left", "top"],
			isStart = !item || item.linkAlign == "start";
		let obj = view.getItem(isStart ? link.source : link.target);
		if (movedItem && obj.id == movedItem.id) obj = { ...obj, ...movedItem };
		const dir = isStart ? 1 : -1;
		let side = isStart ? from : to;
		if (sides[side]) side = sides[side];
		if (!side) side = getIntersectionSide(view, obj, line, dir);
		p = getPoint(view, obj, side);
		const shift = 10;
		if (side == "top") pos = { x: p[0] + shift, y: p[1] - height - shift };
		else if (side == "bottom") pos = { x: p[0] + shift, y: p[1] + shift };
		else if (side == "left")
			pos = { x: p[0] - width - shift, y: p[1] - height - shift };
		else pos = { x: p[0] + shift, y: p[1] - height - shift };
	} else {
		const i = Math.floor(line.length / 2);
		if (line.length % 2) p = line[i];
		else p = getMidPoint(line[i - 1].map(v => v * 1), line[i].map(v => v * 1));
		pos = { x: p[0] - width / 2, y: p[1] - height / 2 };
	}
	return pos;
}
function getMidPoint(a, b) {
	return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
}
export function checkLabelPoint(view, p, item, link) {
	const { width, height } = getItemSize(view, item);
	const s = view.getItem(link.source);
	const sSize = getItemSize(view, s);
	const t = view.getItem(link.target);
	const tSize = getItemSize(view, t);
	return (
		checkPoint(p, s, sSize) &&
		checkPoint(p, t, tSize) &&
		checkPoint([p[0] + width, p[1] + height], s, sSize) &&
		checkPoint([p[0] + width, p[1] + height], t, tSize)
	);
}
/**
 * Calculates coordinates of curve point with t value
 * @param a {Array} - the first point of a curve [x,y]
 * @param  b {Array} - the second point of a curve [x,y]
 * @param  c {Array} - the third point of a curve [x,y]
 * @param  t {number} - the t value
 * @return {Array} point
 */
export function calcCurveTPoint(a, b, c, t) {
	let result = [],
		i = 0;
	while (i < 2) {
		result[i] =
			(1 - t) * (1 - t) * a[i] + 2 * (1 - t) * t * b[i] + t * t * c[i];
		i++;
	}
	return result;
}
/**
 * Calculates coordinates for the curve point that is used for arrow angle calculation
 * @param a {Array} - the first point of a curve [x,y]
 * @param  b {Array} - the second point of a curve [x,y]
 * @param  c {Array} - the third point of a curve [x,y]
 * @return {Array} point
 */
export function calcCurveArrowPoint(a, b, c) {
	return calcCurveTPoint(a, b, c, 0.9);
}

export function getPathCurvePoints(line) {
	line = line.map(a => (webix.isArray(a) ? a : a.split(",")));
	let points = [line[0].join(" ")];
	let d = "M " + points[0];
	for (let i = 1; i < line.length; i += 2) {
		const p = line[i].join(" "),
			p1 = (line[i + 1] || line[i]).join(" ");
		points.push(p, p1);
		d += " Q " + p + " " + p1;
	}
	return { d, points: points.join(",") };
}
export function getCurveControlPoint(p0, p2, pt) {
	let result = [],
		i = 0;
	while (i < 2) {
		result[i] = 2 * pt[i] - 0.5 * p0[i] - 0.5 * p2[i];
		i++;
	}
	return result;
}
export function updatePathPoints(path, submode) {
	let i = submode[0] * 1;
	if (!i || i == path.length - 1) return path;

	if (i && !(i % 2) && i < path.length - 1) {
		// add new control points if a new curve created
		path.splice(i, 0, []);
		path.splice(i + 2, 0, []);
		i++;
	}

	if (i < path.length - 2) path[i + 1] = getMidPoint(path[i + 2], path[i]);

	if (i > 1) path[i - 1] = getMidPoint(path[i - 2], path[i]);

	return path;
}
